<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ $t("COMMON.ROLES") }}</h3>
    <role-list-table :filterReseller="reseller.id" />
  </div>
</template>

<script>
import RoleListTable from "../../RoleManagement/partials/RoleListTable.vue";

export default {
  name: "reseller-view-roles",

  components: { RoleListTable },

  props: ["reseller"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    reseller(reseller) {},
  },
};
</script>
