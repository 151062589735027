<template>
  <div class="kw-tabs-wrapper-content">
    <h3>{{ $t("COMMON.ORGANIZATIONS") }}</h3>
    <organization-list-table :filterReseller="reseller.id" />
  </div>
</template>

<script>
import OrganizationListTable from "../../OrganizationManagement/partials/OrganizationListTable.vue";
export default {
  name: "reseller-view-organizations",

  components: { OrganizationListTable },

  props: ["reseller"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    reseller(reseller) {},
  },
};
</script>
