<template>
  <span class="object-link cursor-pointer" @click.stop="copy">
    {{ element }} <i class="fas fa-copy"></i>
  </span>
</template>

<script>
export default {
  name: "copy-element",

  components: {},

  mixins: [],

  props: {
    element: {
      type: [String, null, undefined],
      default: null,
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {
    async copy() {
      await this.$copyText(this.element);
      this.$notify({
        type: "success",
        timeout: 3000,
        message: this.$t("COMMON.COPIED"),
      });
    },
  },

  watch: {},
};
</script>

<style scoped>
.object-link {
  text-decoration: none;
  color: inherit;
  display: inline-flex;
  align-items: center;
}

.object-link i {
  margin-left: 5px;
  opacity: 0;
  transition: opacity 0.3s;
}

.object-link:hover {
  text-decoration: underline;
}

.object-link:hover i {
  opacity: 1;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
