<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ $t("COMMON.GENERAL_INFORMATIONS") }}</h3>
    <div class="all-infos">
      <div class="all-infos-image">
        <img
          v-if="reseller.logo"
          :src="`${reseller.logo}`"
          class="argon-image"
        />
        <img
          v-else
          src="/img/kw-default-image.svg"
          class="argon-image default"
        />
      </div>
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.NAME") }}</dt>
          <dd>
            {{ reseller.name }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            <div v-html="reseller.excerpt"></div>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.EMAIL") }}</dt>
          <dd>
            {{ reseller.email }}
          </dd>
        </dl>
        <dl class="row">
          <dt>
            {{ $t("COMMON.PHONE") }}
          </dt>
          <dd>
            {{
              reseller.phone_type
                ? $t(`COMMON.PHONE_TYPE_${reseller.phone_type}`)
                : null
            }}:
            {{ reseller.phone }}
            {{
              reseller.phone_extension
                ? ` (ext: ${reseller.phone_extension})`
                : null
            }}
          </dd>
        </dl>
        <dl class="row">
          <dt>
            {{ $t("COMMON.OTHER_PHONES") }}
          </dt>
          <dd>
            <span v-for="(line, index) in reseller.other_phones" :key="index">
              {{ $t(`COMMON.PHONE_TYPE_${line.type}`) }}:
              {{ line.phoneNumber }}
              {{ line.extension ? ` (ext: ${line.extension})` : null }} <br />
            </span>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.ADDRESS") }}</dt>
          <dd>
            {{ reseller.address }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.OWNER") }}</dt>
          <dd>
            <router-link :to="$objectViewRoute(reseller.owner)">
              {{
                `${reseller.owner.firstname} ${reseller.owner.lastname} - ${reseller.owner.email}`
              }}
            </router-link>
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd v-if="reseller.created_at">
            {{ $formatDate(reseller.created_at) }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd v-if="reseller.updated_at">
            {{ $formatDate(reseller.updated_at) }}
          </dd>
        </dl>
      </div>
      <h3 class="mt-3">{{ $t("RESELLERS.CONFIG_MANAGER") }}</h3>
      <div class="all-infos-image">
        <img
          v-if="reseller.config_manager_app_logo"
          :src="`${reseller.config_manager_app_logo}`"
          class="argon-image"
          style="width: 100%"
        />
        <img v-else src="/img/placeholder.jpg" class="argon-image" />
      </div>
      <div class="all-infos-text">
        <dl class="row">
          <dt>
            {{ $t("RESELLERS.CONFIG_MANAGER_APP_NAME") }}
          </dt>
          <dd>
            {{ reseller.config_manager_app_name }}
          </dd>
        </dl>
        <dl class="row">
          <dt>
            {{ $t("RESELLERS.CONFIG_MANAGER_URL_REGEX") }}
          </dt>
          <dd>
            {{ reseller.config_manager_url_regex }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reseller-view-global",

  components: {},

  props: ["reseller"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    reseller(reseller) {},
  },
};
</script>
