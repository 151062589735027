<template>
  <div class="kw-tabs-wrapper-content">
    <h3>{{ $t("COMMON.USERS") }}</h3>
    <user-list-table :filterReseller="reseller.id" />
  </div>
</template>

<script>
import UserListTable from "../../UserManagement/partials/UserListTable.vue";
export default {
  name: "reseller-view-users",

  components: { UserListTable },

  props: ["reseller"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    reseller(reseller) {},
  },
};
</script>
